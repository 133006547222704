export const environment = {
	appName: 'angular-base-project',
	home: '/painel',
	production: false,
	api: 'https://homolog-backend.futurecarbon.com.br',
	url: 'https://baseproject.futurecarbon.com.br',

	passwordRules: {
		minlength: 8,
		minLowercaseCharacterRule: 1,
		minUppercaseCharacterRule: 1,
		minDigitCharacterRule: 1,
		minSpecialCharacterRule: 1
	},
};
