import { SessionState } from '@model/session';
import { LocalStorageService } from './../local-storage.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})
export class TokenService {

  env = environment.api.split('//')[1];
  
  private issuer = {
    
    login: `${this.env}/login`,
    register: `${this.env}/register`,
  };

  constructor(
    private localStorageService: LocalStorageService,
   
  ) {   }

  handleData({ authorisation, user}: SessionState) {
    this.localStorageService.set('auth_token', authorisation.token);
    this.localStorageService.set('user_id', user.id);
  }

  getToken() {
    return this.localStorageService.get('auth_token');
  }
  
  // Verify the token
  isValidToken() {
    const token = this.localStorageService.get('auth_token');
    if(token){
      const payload = this.payload(token)
      if(payload){
        const expiry = payload.exp;
        return (Math.floor((new Date).getTime()) / 1000) <= expiry;

      }else{
        return false;
      }
    }else{
      return false;
    }
    

/*     const token = this.localStorageService.get('auth_token');
    if (token) {
      const payload = this.payload(token);      
      if (payload) {
        const payloadIss =  payload.iss.split('//')[1];
        
        return Object.values(this.issuer).indexOf(payloadIss) > -1
          ? true
          : false;
      }
    } else {
      return false;
    } */
  }

  payload(token: any) {
    const jwtPayload = token.split('.')[1];
    return JSON.parse(atob(jwtPayload));
  }
  
  // User state based on valid token
  isLoggedIn() {
    return this.isValidToken();
  }
  // Remove token
  removeToken() {
    this.localStorageService.remove('auth_token');
  }

  
}
