import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { SessionStore } from "./session.store";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { tap, catchError, map, take, mergeMap, shareReplay } from 'rxjs/operators';
import { Observable, of } from "rxjs";
import { applyTransaction, resetStores } from "@datorama/akita";
import { AuthService } from "@services/auth/auth.service";
import { SessionState } from "@model/session";
import { User, UserInfo } from "@model/user";
import { environment } from "@env/environment";
import { LocalStorageService } from "@services/local-storage.service";

@Injectable({
	providedIn: 'root'
})
export class SessionService {
	constructor(
		private readonly sessionStore: SessionStore,
		private readonly http: HttpClient,
		private readonly router: Router,
		private readonly toastr: ToastrService,
    	private readonly authService: AuthService,
		private localStorageService: LocalStorageService
	) {}

	private _setLoading(status: boolean) {
		this.sessionStore.setLoading(status);
	}

	/* isAuthenticated(): Observable<boolean> {
		return this.authService.isAuthenticated().pipe(
			tap(isAuthenticated => {
				this.sessionStore.update({ isAuthenticated });
			})
		);
	} */

	updateIsAuthenticated(isAuthenticated: boolean) {
		applyTransaction(() => {
			this.sessionStore.setLoading(false);
			this.sessionStore.update({ isAuthenticated });
		});
	}

	logout() {
		this.authService.logout().subscribe(res => {
			this.localStorageService.clear();
		});
		setTimeout(() => {
			resetStores();
			this.router.navigate(['/login']);
		}, 500);
	}
 
  	/*login(username: string, password: string) {
		this.authService.login({username, password})
			.subscribe({
				next: this.handleLoginResponse.bind(this),
				error: this.handleLoginError.bind(this)
			});
	} */

  handleLoginResponse(response: any): void {
    console.log(response)
  }

  handleLoginError(err: any): void {
    console.error(err)
  }

  getUser(id: string): Observable<UserInfo> {
	
	return this.sessionStore
		._select(state => state)
		.pipe(
			mergeMap(userInfo => {
				if (userInfo.id) {
					return of(userInfo);
				}
				return this.getUserFromBack(id);
			})
		);
}

getUserFromBack(id: string): Observable<UserInfo> {
	
	return this.http.get<User>(`${environment.api}/api/user/${id}`).pipe(
		map(userInfo => userInfo.user),
		tap(userInfo => this.sessionStore.update({...userInfo })),
		shareReplay()
	);
}

	
}