import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '@env/environment';
import { Auth, PasswordReset } from '@model/auth';
import { SessionState } from '@model/session'
import { LocalStorageService } from '@services/local-storage.service';
import { TokenService } from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private readonly http: HttpClient,
    private localStorageService: LocalStorageService,
    private tokenService: TokenService
  ) { }

  // User registration
  register(user: Auth): Observable<any> {
    return this.http.post<SessionState>(`${environment.api}/register`, user);
  }
  // Reset Password
  resetPassword(body: PasswordReset): Observable<any> {
    return this.http.post(`${environment.api}/reset-password`, body);
  }
  // update Password
  updatePassword(body): Observable<any> {
    return this.http.post(`${environment.api}/update-password`, body);
  }
  // Login
  signin(user: Auth): Observable<any> {
    return this.http.post<any>(`${environment.api}/login`, user);
  }
  // Access user profile
  profileUser(): Observable<any> {
    return this.http.get(`${environment.api}/auth/user-profile`);
  }

  logout(): Observable<any> {
    return this.http.post<any>(`${environment.api}/logout`,{});
  }

  isAuthenticated(): boolean {
    return this.tokenService.isValidToken();
  }
/* 
  login(auth: Auth): Observable<any> {
    return this.http.post<any>(`${environment.api}/login`, auth);
  }

  logout(): Observable<any> {
    return this.http.get<any>(`${environment.api}/logout`);
  }
 
  */
}
