import { UserInfo } from '@model/user';
import { Injectable } from "@angular/core";
import { akitaConfig, Store, StoreConfig } from "@datorama/akita";
import { SessionState } from "@model/session";

export function createInitialState(): UserInfo {
	return {
		id: null,
		name: null,
		email: null,
		email_verified_at: null,
		created_at: null,
		updated_at: null,
	};
}

akitaConfig({
	resettable: true
  })

@Injectable({
	providedIn: 'root'
})
@StoreConfig({ name: 'sessionState' })
export class SessionStore extends Store<UserInfo> {
	constructor() {
		super(createInitialState());
	}

	clearSession(): void {
		this._setState(state => createInitialState());
	}

	update(userInfo){
		
		this._setState(state => ({
			...state, ...userInfo
		}))
	}
}