import { environment } from './../environments/environment';
import { SessionService } from './store/session/session.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from '@services/local-storage.service';
import { TranslateService } from '@ngx-translate/core';

import { NgxHttpLoaderService, NgxLoader } from 'ngx-http-loader'; // <============

@Component({
  selector: 'app-root',
  template: `
  <ngx-loading-bar
  [color]="'rgba(255, 255, 255, 0.5)'" 
  [height]="'3px'"
  [includeSpinner]="false"
  [includeBar]="true"
  [diameter]="'10px'"
  [fixed]="true"
  [ref]="'http'"></ngx-loading-bar>
  
  <ngx-http-loader  
    [opacity]="0.7" 
    [title]="''" 
    [subTitle]="''" 
    [loader]="loader.MULTICIRCLE" 
    [iconColor]="'orange'" 
    [backdropBackgroundColor]="'rgba(255, 255, 255, 0.0)'"
    [filterUrls]="filterUrls">
  </ngx-http-loader>
  <router-outlet></router-outlet>
  `,
})
export class AppComponent implements OnInit {
  public loader = NgxLoader;
  public filterUrls: string[] = [];//[`${environment.api}/login`, `${environment.api}/api/user`];
  
    constructor(
    public sessionService: SessionService,
    public localStorageService: LocalStorageService,
    public translate: TranslateService,
    private ngxhttploader: NgxHttpLoaderService
    
  ){
    /*  // show the spinner
     this.ngxhttploader.show();
        
     // hide the spinner
     setTimeout(() => {
         this.ngxhttploader.hide();
     }, 15000); */
  }
  
  ngOnInit():void{
    this.getNaviLang();
    if(this.localStorageService.get('auth_token')){
      this.sessionService.getUser(this.localStorageService.get('user_id')).subscribe();
    }
  }

  getNaviLang(): void{
    const lang = navigator.language === 'pt-BR' ? navigator.language : 'en'; 
    this.translate.addLangs(['pt-BR','en']);
    this.translate.setDefaultLang(lang);
  }

}
