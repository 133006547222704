import { Injectable } from '@angular/core';
import { Router, CanLoad } from '@angular/router';
import { of, Observable } from 'rxjs';
import { TokenService } from '@services/auth/token.service';


@Injectable({
	providedIn: 'root'
})
export class SessionGuard implements CanLoad {
	constructor(private router: Router, private tokenService: TokenService) {}

	canLoad(): Observable<any> {
		return this.validate();
		
	}

	private validate() {
		
		if(!this.tokenService.isValidToken()){
			this.router.navigateByUrl('/login');
		}
		return of(this.tokenService.isValidToken());
	}
}
